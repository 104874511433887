import React from "react";
import Slider from "react-slick";
import CardItemComponent from "./CardItemComponent";
import "./CardSlider.css";

class CardSliderComponent extends React.Component {
  
  render() {
    const posts = this.props.posts;
    const settings = {
      dots: true,
      speed: 500,
      slidesToShow: this.props.slidesToShow,
      slidesToScroll: this.props.slidesToShow,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    
    return (
      <div className="slider-container">
        <Slider ref={c => (this.slider = c)} {...settings}>
          {posts.map((el,idx) => {
            return (
            <CardItemComponent key={idx} image={el.node.featuredImage.node.localFile.childImageSharp.fluid} 
            title={el.node.title} date={el.node.date} 
            text={el.node.excerpt} link={el.node.slug}
            alt_text={el.node.featuredImage.node.alt_text} />
          )})}
        </Slider>
      </div>
    );

    //
    //   {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((el, idx) => (
    //     <div className={styles.slide}>
    //       <CardItemComponent />
    //     </div>
    //   ))}
    // </div>
  }
}
export default CardSliderComponent;
