import React from "react";
import { Link } from "gatsby"
import "./Cards.css";
import Img from "gatsby-image"

const CardItemComponent = ({alt_text, image, title, date, text, link}) => {
  return (
    <div className="cards-container">
      <div className="cards">
        {/* <div className="card-top" /> */}
        <div className="card-image">
          <Img fluid={image} alt={alt_text}/>
        </div>
        <div className="card-body">
          <p className="card-title" dangerouslySetInnerHTML={{ __html:title}}></p>
          <p className="card-date">{date}</p>
          <hr />
          <div className="card-text" dangerouslySetInnerHTML={{ __html:text.replace(/<a class="moretag.*/, '...')}}></div>
          <div className="card-link">
            <Link to={"/" + link}>Read More</Link>
            {/* <a href="/">Read More</a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardItemComponent;
